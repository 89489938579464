<template>
  <div style="padding-top: 50px">
    <div class="BPCheaderIcon" :style="
      `background-image: url(${ logoTheme() || '/favicon-primary.png' });`
    "></div>
    <KTPortlet style="
        width: 500px;
        max-width: 90%;
        margin: 0 auto;
        xbackground: var(--dark);
      ">
      <template slot="body">
        <div class="loginBody">
          <Loader v-if="form.busy" :inContent="true" :noText="true" />
          <div v-if="isInMaintMode">
            <h1 style="color: var(--warning)">This portal is currently in maintenance mode.</h1>
            <h1 style="color: var(--warning)">Please check back later.</h1>
          </div>
          <v-form v-else-if="!form.busy" role="form" ref="loginForm" v-model="form.loginFormValid" @submit="submit"
            lazy-validation>
            <div style="padding: 20px">
              <div v-if="form.step === 0">
                <h1 style="
                    margin: 0 auto;
                    text-align: center;
                    margin-bottom: 40px;
                  ">
                  {{
                                    /*loginRegisterText*/ showLoginPasswordField
                    ? "Login/Register"
                      : "Enter your email"
                  }}
                </h1>
                <v-text-field v-model="form.email" name="email" ref="loginFormEmail" autocapitalize="off"
                  autocorrect="off" spellcheck="false" type="text" autocomplete="email" label="Email Address"
                  :rules="$vrules.email" :required="true"></v-text-field>
                <v-text-field v-if="showLoginPasswordField" v-show="
                  ($refs.loginFormEmail || {}).valid === true ||
                  form.password.length > 0 ||
                  loginRegisterText === 'Register'
                " v-model="form.password" type="password" name="password" minlength="8" ref="loginFormPassword"
                  autocapitalize="off" autocorrect="off" spellcheck="false" autocomplete="password" label="Password"
                  :rules="$vrules.password" :required="true"></v-text-field>
                <!-- <button
                  v-if="
                    loginRegisterText !== 'Register' &&
                      form.password.length === 0
                  "
                  type="button"
                  style="margin-top: 50px; float: left"
                  class="btn btn-info"
                  @click="loginRegisterText = 'Register'"
                >
                  Register
                </button> -->
              </div>
              <div v-if="form.step === 1">
                <h1 style="
                    margin: 0 auto;
                    text-align: center;
                    margin-bottom: 40px;
                  ">
                  Confirm Details
                </h1>
                <v-text-field v-model="form.name" type="text" name="first-name" autocapitalize="on" autocorrect="off"
                  spellcheck="false" autocomplete="first-name" label="First Name"
                  :rules="$vrules.basicText('First Name')" :required="true"></v-text-field>
                <v-text-field v-model="form.surname" type="text" name="last-name" autocapitalize="on" autocorrect="off"
                  spellcheck="false" autocomplete="last-name" label="Last Name" :rules="$vrules.basicText('Last Name')"
                  :required="true"></v-text-field>
                <div class="row flex">
                  <div class="col-xl-6 no-margin-b">
                    <b-form-select style="border-radius: 0;" v-model="form.cell_prefix" class="mb-3">
                      <option v-for="item in localeNumbers" v-bind:key="item.code" :value="item.dial_code">
                        {{ item.dial_code }} ({{ item.name }})
                      </option>
                    </b-form-select>
                  </div>
                  <div class="col-xl-6 no-margin-b">
                    <v-text-field v-model="form.cell" type="text" name="cell" autocapitalize="on" autocorrect="off"
                      spellcheck="false" autocomplete="cell" label="Cell Number" :rules="$vrules.basicText('Last Name')"
                      :required="true"></v-text-field>
                  </div>
                  <div class="col-xl-12 no-margin-b">
                    We will use your cell to
                    {{
                      config.passwordlessAuth === "whatsapp"
                        ? "WhatsApp/SMS"
                        : "verify"
                    }}
                    you for a second tier of verification.
                  </div>
                </div>
              </div>

              <div v-if="form.step === 4">
                <h1 style="
                    margin: 0 auto;
                    text-align: center;
                    margin-bottom: 40px;
                  ">
                  2 Factor Verification
                </h1>
                <v-text-field v-model="form.optToken" @keyup="otpTokenChanged()" @change="otpTokenChanged()" name="otp"
                  ref="optToken" autocapitalize="off" autocorrect="off" spellcheck="false" type="text"
                  autocomplete="otp" label="OTP" :rules="$vrules.otp" :required="true"></v-text-field>
              </div>

              <div v-if="form.step === 6">
                <template v-if="
                  ['call', 'whatsapp'].indexOf(config.passwordlessAuth) >= 0
                ">
                  <h1 style="
                    margin: 0 auto;
                    text-align: center;
                    margin-bottom: 40px;
                  ">
                    Second Factor Authentication
                  </h1>
                  <Loader :inContent="true" :noText="true" />
                  <div style="margin-top: 20px; text-align: center;">
                    Check your phone as you will receive a
                    {{ config.passwordlessAuth }} to verify your login.
                  </div>
                </template>
                <template v-else>
                  <h1 style="
                    margin: 0 auto;
                    text-align: center;
                    margin-bottom: 40px;
                  ">
                    Email Verification
                  </h1>
                  <v-text-field v-model="form.registerOptToken" name="otp" ref="registerOTP" autocapitalize="off"
                    autocorrect="off" spellcheck="false" type="text" autocomplete="otp" label="OTP" :rules="$vrules.otp"
                    :required="true"></v-text-field>
                </template>
              </div>

              <!-- <div v-if="form.step === 7">
                <h1
                  style="
                    margin: 0 auto;
                    text-align: center;
                    margin-bottom: 40px;
                  "
                >
                  Email Verification
                </h1>
                <v-text-field
                  v-model="form.emailPasswordOTP"
                  name="otp"
                  ref="emailPasswordOTP"
                  autocapitalize="off"
                  autocorrect="off"
                  spellcheck="false"
                  type="text"
                  autocomplete="otp"
                  label="OTP"
                  :rules="$vrules.otp"
                  :required="true"
                ></v-text-field>
              </div> -->
              <!-- <div>
                <div
                  :style="`display: inline-block; width: ${
                    editDeviceObject.templateId === null
                      ? '100%'
                      : 'calc(100% - 42px)'
                  };`"
                >
                  <v-autocomplete
                    v-if="!$tools.isNullOrUndefined(templates)"
                    :items="getTemplates"
                    item-text="name"
                    item-value="value"
                    v-model="editDeviceObject.templateId"
                    xplaceholder="Start typing to Search"
                  ></v-autocomplete>
                </div>
                <div
                  style="display: inline-block; margin-left: 5px"
                  v-if="editDeviceObject.templateId !== null"
                >
                  <b-btn @click="editDeviceObject.templateId = null" small text
                    >X</b-btn
                  >
                </div>
              </div> -->

              <div style="width: 10px; height: 50px"></div>

              <b-button style="float: right" adisabled="!form.loginFormValid" @click="submit" type="submit" :variant="
                (form.loginFormValid ? 'success' : 'danger') + ' text-light'
              ">{{ buttonText }}</b-button>
            </div>
          </v-form>
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { mapGetters } from "vuex";
//import KTUtil from "@/assets/js/util";
//import KTWizard from "@/assets/js/wizard";
import Swal from "sweetalert2";
import Loader from "@/views/partials/content/Loader.vue";
//import { VERIFY_AUTH } from "@/store/auth.module";
//import axios from "axios";
import { DO_LOGIN, VERIFY_AUTH } from "@/store/auth.module";
//import { Gravatar } from "@/common/gravatar.js";

export default {
  components: {
    KTPortlet,
    Loader
  },
  data() {
    return {
      loginRegisterText: "Next", //"Login/Register",
      form: {
        busy: true,
        step: 0,
        // 0: Login (Email, Password)
        // 1: Regiser (Name*, Surname, Cell)
        // 3: Setup 2FA
        // 4: 2FA
        // 5: Load session
        // 6: Register 2FA
        loginFormValid: false,
        email: "",
        password: "",
        name: "",
        cell: "",
        cell_prefix: "",
        surname: "",
        optToken: "",
        registerOptToken: "",
        emailPasswordOTP: "",
        whatsappToken: "",
        resetToken: undefined
      },
      steps: {},
      stepsLoading: false,
      stepsStage: 0,
      wizard: null,
      localeNumbers: null,
      //wsSessionId: null
    };
  },
  mounted() {
    const self = this;
    if (self.$tools.isNullOrUndefined(self.$wsSessionId())) {
      self.$router.push("/Auth/Load");
    }
    //self.$data.wsSessionId = self.$wsSessionId();
    self.$eventBus.on(`ws-session`, x => {
      if (self.$tools.isNullOrUndefined(x)) {
        self.$router.push("/Auth/Load");
      }
    });
    self.$eventBus.on(`ws-auth-login-assistant`, x => {
      Swal.close();
      if (x.status === "approved" && x.data.session === self.$wsSessionId()) {
        if (x.data.type === "whatsapp") {
          self.$data.form.whatsappToken = x.data.optCode;
          self.submit();
        }
      } else {
        Swal.fire({
          timer: 60000,
          title: "",
          text: "Verification failed or timed out.",
          icon: "danger",
          confirmButtonClass: "btn btn-secondary"
        });
      }
    });

    self.$data.form.cell_prefix = this.config.self.defaultCellCountry;
    this.$store.dispatch(VERIFY_AUTH).then(x => {
      if (x) return self.restoreUrl();
      self.$data.form.busy = false;

      let lastURL = window.sessionStorage.getItem("lastURL");
      if (
        lastURL !== undefined &&
        lastURL !== null &&
        lastURL !== "" &&
        lastURL.indexOf("SCEM=") > 0
      ) {
        let resetToken = lastURL
          .substring(lastURL.indexOf("resetToken="))
          .split("&")[0]
          .split("#")[0]
          .split("=")[1];
        self.form.resetToken = decodeURIComponent(resetToken);
        let email = lastURL
          .substring(lastURL.indexOf("SCEM="))
          .split("&")[0]
          .split("#")[0]
          .split("=")[1];
        self.form.email = decodeURIComponent(email);
        let cell = lastURL
          .substring(lastURL.indexOf("SCCN="))
          .split("&")[0]
          .split("#")[0]
          .split("=")[1];
        self.form.cell = decodeURIComponent(cell);
        self.$nextTick(() => {
          self.submit();
        });
        return;
      }
    });
    //this.setupWizard();
    // Validation before going to next page
    //this.wizard.on("beforeNext", function (/*wizardObj*/) {
    // validate the form and use below function to stop the wizard's step
    // wizardObj.stop();
    //});
    // Change event
    //this.wizard.on("change", function (/*wizardObj*/) {
    //  setTimeout(function () {
    //    KTUtil.scrollTop();
    //  }, 500);
    //});
  },
  computed: {
    isInMaintMode() {
      if (this.config === undefined) return false;
      if (this.config === null) return false;
      if (this.config.self === undefined) return false;
      if (this.config.self === null) return false;
      return this.config.self.maintMode === true;
    },
    /*localeNumbers () {
      if (this.localeNumbers === null) return;
      return this.localeNumbers.sort(x => x)
    },*/
    ...mapGetters(["layoutConfig", "config", "isAuthenticated", "logoTheme"]),
    showLoginPasswordField() {
      return (
        `${ this.config.passwordlessAuth || "" }` == "" ||
        `${ this.config.passwordlessAuth || "none" }` == "none"
      );
    },
    buttonText() {
      if (this.form.step === 0) return this.loginRegisterText;
      if (this.form.step === 1) return "Register";
      if (this.form.step === 4) return "Verify";
      if (this.form.step === 6) {
        if (this.form.registerOptToken.length !== 6) {
          return "Re-Send";
        }
        return "Verify";
      }
      return "Next";
    },

    hostname: () => window.location.hostname
  },
  methods: {
    otpTokenChanged() {
      if (`${ this.form.optToken }`.length === 6) {
        try {
          let parsedOtp = Number.parseInt(`${ this.form.optToken }`);
          if (parsedOtp == Number.NaN) return;
          this.submit();
        } catch (e) {
          return;
        }
      }
    },
    restoreUrl() {
      return this.$router.push("/Auth/Clients");
    },
    postLogin() {
      const self = this;
      if (self.$tools.isNullOrUndefined(self.$wsSessionId())) {
        return self.$router.push("/Auth/Load");
      }
      return new Promise((resolve, reject) => {
        let cellNum = self.form.cell;
        if (cellNum !== null && cellNum.length > 1 && cellNum.indexOf('+') !== 0) {
          cellNum =
            self.form.cell_prefix +
            (cellNum[0] === "0" ? self.form.cell.substring(1) : self.form.cell);
        }
        let fields = {
          session: self.$wsSessionId(),
          resetToken: self.form.resetToken,
          email: self.form.email,
          password: self.form.password,
          optToken: self.form.optToken,
          emailToken: self.form.registerOptToken,
          whatsappToken: self.form.whatsappToken,
          firstName: self.form.name,
          lastName: self.form.surname,
          cell: cellNum
        };
        self.$api
          .post2("auth", "auth", fields)
          .then(x => {
            self.form.whatsappToken = "";
            self.form.optToken = "";
            self.form.registerOptToken = "";
            if (x.status === 206) {
              return resolve({
                ok: false,
                register: false,
                registerOTP: false,
                optRequired: true
              });
            }
            if (x.status === 202) {
              return resolve({
                ok: true,
                register: false,
                registerOTP: false,
                optRequired: false,
                tokenData: x.data
              });
            }
            if (x.status === 201) {
              return resolve({
                ok: false,
                register: false,
                registerOTP: true,
                registerOTPMessage: true,
                optRequired: false
              });
            }
            reject(x);
          })
          .catch(x => {
            if (x.response === undefined || x.response === null)
              return reject(x);
            if (x.response.status === 404) {
              return resolve({
                ok: false,
                register: true,
                registerOTP: false,
                optRequired: false
              });
            }
            if (x.response.status === 408) {
              return resolve({
                ok: false,
                register: false,
                registerOTP: false,
                registerOTPMessage: false,
                optRequired: false,
                message: "Invalid OTP"
              });
            }
            if (x.response.status === 418) {
              return resolve({
                ok: false,
                register: false,
                registerOTP: true,
                registerOTPMessage: false,
                optRequired: false
              });
            }
            if ([400, 403].indexOf(x.response.status)) {
              return resolve({
                ok: false,
                register: false,
                registerOTP: false,
                optRequired: false
              });
            }
            reject(x);
          });
      });
    },
    submit: async function(e) {
      const self = this;
      if (e !== undefined) e.preventDefault();
      if (this.form.busy) return;

      if (this.form.step === 6 && this.form.registerOptToken.length !== 6) {
        this.form.registerOptToken = "000000";
        await new Promise(resolve => self.$nextTick(resolve));
      }
      if (this.$refs.loginForm.validate()) {
        this.$data.form.busy = true;
        self
          .postLogin()
          .then(x => {
            if (x.ok) {
              self.$store.dispatch(DO_LOGIN, x.tokenData).then(() => {
                self.$nextTick(() => {
                  self.$store.dispatch(VERIFY_AUTH).then(x => {
                    if (x) return self.restoreUrl();
                    Swal.fire({
                      timer: 60000,
                      title: "Error",
                      text: "A weird auth error occured",
                      icon: "error",
                      confirmButtonClass: "btn btn-danger"
                    });
                    self.$log.error(x);
                  });
                });
              });
              return;
            }
            if (x.optRequired) {
              self.$data.form.step = 4;
              self.$data.form.busy = false;
              return;
            }
            if (x.register) {
              self.$api
                .getAsset("telecodes.json")
                .then(x => {
                  self.$data.localeNumbers = x.data;
                })
                .catch(x => {
                  self.$log.error(x);
                });
              /*Gravatar.getNewProfile(self.form.email)
                .then((x) => {
                  console.log(x);
                  self.$data.form.step = 1;
                  self.$data.form.busy = false;
                })
                .catch(() => {*/
              self.$data.form.step = 1;
              self.$data.form.busy = false;

              let lastURL = window.sessionStorage.getItem("lastURL");
              if (lastURL !== undefined && lastURL !== null && lastURL !== "") {
                if (lastURL.indexOf("SCFN=") > 0) {
                  let email = lastURL
                    .substring(lastURL.indexOf("SCFN="))
                    .split("&")[0]
                    .split("#")[0]
                    .split("=")[1];
                  self.form.name = decodeURIComponent(email);
                }
                if (lastURL.indexOf("SCLN=") > 0) {
                  let email = lastURL
                    .substring(lastURL.indexOf("SCLN="))
                    .split("&")[0]
                    .split("#")[0]
                    .split("=")[1];
                  self.form.surname = decodeURIComponent(email);
                }
                if (
                  lastURL.indexOf("SCFN=") > 0 ||
                  lastURL.indexOf("SCLN=") > 0
                )
                  self.submit();
              }
              //});
              return;
            }
            if (x.registerOTP) {
              self.$data.form.step = 6;
              self.$data.form.busy = false;
              if (x.registerOTPMessage) {
                if (
                  ["call", "whatsapp"].indexOf(self.config.passwordlessAuth) >=
                  0
                ) {
                  Swal.fire({
                    timer: 60000,
                    title: "Check your phone",
                    text:
                      "We've sent you verification message",
                    icon: "success",
                    confirmButtonClass: "btn btn-success"
                  });
                  return;
                } else {
                  Swal.fire({
                    timer: 60000,
                    title: "Check your email",
                    text:
                      "We've sent you a secret code, please check your email for it",
                    icon: "success",
                    confirmButtonClass: "btn btn-success"
                  });
                }
              }
              return;
            }
            self.$data.form.busy = false;
            self.$log.error(x);
            if (x.message !== undefined) {
              Swal.fire({
                timer: 60000,
                title: "Error",
                text: x.message,
                icon: "error",
                confirmButtonClass: "btn btn-danger"
              });
              return;
            }
            Swal.fire({
              timer: 60000,
              title: "Access Denied",
              text: "Authentication failed",
              icon: "error",
              confirmButtonClass: "btn btn-danger"
            });
          })
          .catch(x => {
            self.$data.form.busy = false;
            Swal.fire({
              timer: 60000,
              title: "Woops",
              text: "An error occured",
              icon: "error",
              confirmButtonClass: "btn btn-danger"
            });
            self.$log.error(x);
          });
        /*setTimeout(() => {
          self.$data.form.step = 1;
          self.$data.form.busy = false;
        }, 2000);*/
      } else {
        Swal.fire({
          timer: 60000,
          title: "Validation Error",
          text:
            "Some fields were not valid, please check the message below each field (in red) for further information.",
          icon: "error",
          confirmButtonClass: "btn btn-danger"
        });
      }
      /*if (false === self.wizard)
      /*const self = this;
      if (self.wizard.currentStep == 2) {
        self.$data.stepsStage = 1;
        self.wizard.totalSteps = 4;
        self.wizard.goTo(1);
        self.$nextTick(() => {
          self.wizard.goTo(3);
        });
      } else
        Swal.fire({
          timer: 60000,
          title: "",
          text: "The application has been successfully submitted!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });*/
    },
    // Log the user in
    login() {
      this.$auth.loginWithPopup();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: "http://localhost:8083/Auth" //window.location.origin,
      });
    }
  },
  /*mounted() {
    if (window.addEventListener) {
      window.addEventListener("message", this.authNotification);
    } else {
      window.attachEvent("onmessage", this.authNotification);
    }
  },*/
  beforeDestroy() {
    Swal.close();
    this.$eventBus.off(`ws-auth-login-assistant`);
    this.$eventBus.off(`ws-session`);
    /*if (window.addEventListener) {
      window.removeEventListener("message", this.authNotification);
    } else {
      window.detachEvent("onmessage", this.authNotification);
    }*/
  }
  /*methods: {
    authNotification(event) {
      if (typeof event.data === "object") {
        if (event.data.action === "AUTH-IFRAME") {
          console.log(event.data);
          if (event.data.status) {
            console.log("OK");
            this.$keycloak.finalAuth();
          } else {
            console.log("FAIL");
          }
        }
      }
    },
  },*/
};
</script>